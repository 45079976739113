import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import agent from "../../app/api/agent";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";

export default observer(function AdminHome() {

    const navigate = useNavigate();

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const {userStore} = useStore();
    const {isAdmin, user} = userStore;

    const [log, setLog] = useState("");

    // Redirect to home when logged in, or if already logged in
    useEffect(() => {
        if(!user || !isAdmin) navigate('/');
    }, [user, navigate, isAdmin]);

    const handlePollRss = async () => {
        setLog('Running...');
        const result = await agent.Admin.pollFeeds();
        setLog(JSON.stringify(result));
    }

    const handleGeneratePosts = async () => {
        setLog('Running...');
        const result = await agent.Admin.generateFeedPosts();
        setLog(JSON.stringify(result));
    }

    const handleReRank = async () => {
        setLog('Running...');
        const result = await agent.Admin.reRank();
        setLog('Done!');
    }

    return (

        <div className="flex baseColor h-screen">

            {/* Content area */}
            <div className="relative flex flex-col flex-1">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>

                    <div className="relative px-4 sm:px-6 lg:px-8 py-8 md:py-0 w-full max-w-9xl mx-auto">

                        <div className="xl:flex">

                            {/* Left + Middle content */}
                            <div className="md:flex flex-1">

                                {/* Middle content */}
                                <div className="flex-1 md:ml-8 xl:mx-4 2xl:mx-8">
                                    <div className="md:py-4">

                                        {/* Buttons group */}
                                        <div className="mb-1">
                                            <div className="w-full flex flex-wrap -space-x-px">
                                                <button className="btn grow bg-slate-50 border-slate-200 hover:bg-white text-orange-600 rounded-none first:rounded-l last:rounded-r"
                                                    onClick={() => handlePollRss()}>Poll RSS & Reddit Feeds</button>
                                                <button className="btn grow bg-slate-50 border-slate-200 hover:bg-white text-orange-600 rounded-none first:rounded-l last:rounded-r"
                                                    onClick={() => handleGeneratePosts()}>Generate Posts</button>
                                                <button className="btn grow bg-slate-50 border-slate-200 hover:bg-white text-orange-600 rounded-none first:rounded-l last:rounded-r"
                                                    onClick={() => handleReRank()}>ReRank</button>
                                            </div>
                                        </div>

                                    </div>

                                        {log && (
                                            <div className="px-4 py-2 rounded-md bg-slate-50 shadow-md">
                                                <div className="mx-auto">
                                                    <h1 className="text-slate-800 font-medium text-lg">Logs</h1>
                                                        <div className="text-rose-800 text-md p-3 mx-auto bg-white rounded-sm shadow">
                                                            {log}
                                                        </div>
                                                </div>
                                            </div>
                                        )}

                                </div>                

                            </div>
                        </div>

                    </div>
                    
                </main>

            </div>

        </div>
    )
})