import { formatDistanceToNow } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { toast } from "react-toastify";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { Comment } from "../../app/models/comment";
import { Post } from "../../app/models/post";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";
import CommentUpvoteButton from "./CommentUpvoteButton";
import ForumLeftContent from "./LeftNavBarContent";
import ForumRightContent from "./ForumRightContent";
import PostCommentForm from "./PostCommentForm";

interface Props {
    comment: Comment;
    displayChildren: boolean;
}

export default observer (function CommentRow({comment, displayChildren}: Props) {
    
    const {postStore, userStore} = useStore();
    const {commentMap, deleteComment} = postStore;
    const {user} = userStore;

    const [replyOpen, setReplyOpen] = useState(false);
    const userProfile = `/users/${comment.username}`;

    const childComments = commentMap.get(comment.id);

    return (
        <ul className="space-y-5">
            {/* Comment */}
            <li className="relative pl-9 space-y-5">
                {/* Comment wrapper */}
                <div className="flex items-start">
                    
                    {/* Comment upvote */}
                    <CommentUpvoteButton comment={comment} />

                    {/* Comment content */}
                    <div>
                        {/* Comment text */}
                        <div className="grow text-sm text-slate-800 space-y-2 mb-2">
                            {comment.deletedAt == null ? (
                                <span>{comment.body}</span>
                            ) : (
                                <span className="text-slate-400 font-sm italic">Deleted {formatDistanceToNow(new Date(comment.deletedAt + 'Z'))} ago</span>
                            )}
                        </div>
                        {/* Comment footer */}
                        <div className="flex flex-wrap text-xs">
                            <div className="flex items-center after:block after:content-['·'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-2">
                                <Link className="font-medium text-indigo-500 hover:text-indigo-600"
                                    to={userProfile}>
                                {comment.username}
                                </Link>
                                <span className="pl-1 text-slate-500"> {formatDistanceToNow(new Date(comment.createdAt + 'Z'))} ago</span>
                            </div>
                            <div className="flex items-center after:block after:content-['·'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-2">
                                <button className="font-medium text-slate-500 hover:text-slate-600" 
                                onClick={() => setReplyOpen(!replyOpen)}>
                                    {!replyOpen ? "Reply" : "Close Reply Form"}
                                </button>
                            </div>
                            {comment.username === user?.username && comment.deletedAt === null && (
                                <div className="flex items-center after:block after:content-['·'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-2">
                                    <button className="font-medium text-rose-800 hover:text-rose-600" 
                                    onClick={() => deleteComment(comment.id)}>
                                        Delete
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {replyOpen && (
                    <div className="pl-5 border-l border-l-slate-400">
                        <h3 className="text-sm text-slate-500 italic">A thread reply to {comment.username}'s comment above</h3>
                        <PostCommentForm comment={comment} />
                    </div>
                )}

                {/* Nested comments */}
                {displayChildren && childComments?.map((childComment) => (
                    <CommentRow comment={childComment} displayChildren={true} key={childComment.id} />
                ))}
            </li>
        </ul>
    )
})