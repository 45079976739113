import React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { observer } from 'mobx-react-lite';
import { ErrorMessage, Form, Formik } from 'formik';
import { useStore } from '../../app/stores/store';
import MyTextInput from '../../app/common/form/MyTextInput';
import ValidationErrors from '../errors/ValidationErrors';
import MyTextArea from '../../app/common/form/MyTextArea';

export default observer (function PostCreateForm() {

  const {postStore} = useStore();
  const {create} = postStore;

  const URL = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i

  return (
    <div className="max-w-lg mx-auto">
            <h1 className="text-3xl text-slate-800 font-bold mb-6">✨ Create Your Post!</h1>
            <Formik
                initialValues={{title: '', url: '', body: '', error: null}}
                onSubmit={(values, {setErrors}) => create(values).catch(error => 
                    setErrors({error}))}
                    validationSchema={Yup.object({
                        title: Yup.string().required(),
                        url: Yup.string().matches(URL, "Enter a valid URL").optional(),
                        body: Yup.string().optional()
                    })}
            >
                {({handleSubmit, isSubmitting, errors, isValid, dirty}) => (
                    <Form className='ui form error' onSubmit={handleSubmit} autoComplete='off'>
                        <div>
                            <div className="space-y-4">
                                <MyTextInput name='title' label='Title' placeholder='Title' />
                                <MyTextInput name='url' label='URL' placeholder='URL' />
                                <MyTextArea name='body' label='Body' placeholder='Body' disabled={false} rows={7} />
                                <ErrorMessage 
                                    name='error' render={() => 
                                        <ValidationErrors errors={errors.error} /> }
                                />
                            </div>
                            <div className="flex items-center justify-between mt-6">
                                <div className="mr-1">
                                    <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox" />
                                    <span className="text-sm ml-2">I have read and accept the rules.</span>
                                    </label>
                                </div>
                                <button
                                    disabled={!isValid || !dirty || isSubmitting} 
                                    className="btn text-md bg-black hover:text-yellow-500 rounded-none text-white ml-3"
                                    type='submit'
                                    >Create Post ✒️</button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
  );
})
