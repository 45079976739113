import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { toast } from "react-toastify";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";
import CommentRow from "./CommentRow";
import ForumLeftContent from "./LeftNavBarContent";
import ForumRightContent from "./ForumRightContent";
import PostCommentForm from "./PostCommentForm";

export default observer(function PostComments() {

    const {postStore} = useStore();
    const {commentMap} = postStore;

    const loadMoreCommentsOption = false;

    const rootLevelComments = commentMap.get(null);

    return (
        <div className="mt-4">

            <h3 className="font-semibold text-slate-800 mb-4">Comments</h3>

            {/* Comments */}
            <ul className="space-y-5">
                {rootLevelComments?.map((comment) => (
                    <CommentRow comment={comment} displayChildren={true} key={comment.id} />
                ))}
            </ul>

            {!rootLevelComments || rootLevelComments.length < 1 && (
                <div className="text-center mt-5">
                    <span className="text-sm font-small text-slate-500 hover:text-slate-600 italic"
                        >No comments yet...</span>
                </div>
            )}

            {/* View more link */}
            {loadMoreCommentsOption && (
                <div className="text-center mt-5">
                    <button className="text-sm font-medium text-indigo-500 hover:text-indigo-600"
                        >View More Comments</button>
                </div>
            )}
        </div>
    )
})