import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ReactTimeago from "react-timeago";
import { toast } from "react-toastify";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";
import ForumLeftContent from "../posts/LeftNavBarContent";
import ForumRightContent from "../posts/ForumRightContent";
import ProfileDetails from "./ProfileDetails";

export default observer(function UserProfilePage() {

    const {profileStore} = useStore();
    const {loading, loadProfile, selectedProfile} = profileStore;
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const {username} = useParams<{username: string}>();

    useEffect(() => {
        if(username) loadProfile(username);
    }, [username, loadProfile]);

    return (
        <div className="flex baseColor h-full">

            {/* Content area */}
            <div className="relative flex flex-col flex-1">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="relative px-4 sm:px-6 lg:px-8 py-8 md:py-0 w-full max-w-9xl mx-auto">

                        <div className="xl:flex">

                        {/* Left + Middle content */}
                        <div className="md:flex flex-1">

                            {/* Left content */}
                            <ForumLeftContent />

                            {/* Middle content */}
                            <div className="flex-1 md:ml-8 xl:mx-4 2xl:mx-8">
                                <div className="md:py-8">

                                    {/* Forum entry */}
                                    <ProfileDetails />

                                </div>
                            </div>                

                        </div>

                        {/* Right content */}
                        <ForumRightContent />              

                        </div>

                    </div>
                </main>

            </div>
        </div>
    )
})