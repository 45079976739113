import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { User, UserFormValues } from "../models/user";
import { store } from "./store";
import { history } from "../..";
import { useNavigate } from "react-router-dom";
import { isGenerator } from "mobx/dist/internal";
import { bool } from "yup";

export default class UserStore{

    user: User | null = null;

    myPostVotes: string[] = [];
    myCommentVotes: string[] = [];

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.user,
            () => this.loadVotes()
        )
    }

    get isLoggedIn() {
        return !!this.user;
    }

    get isAdmin() {
        return this.user ? this.user?.isAdmin : false;
    }

    hasVotedOnPost = (postId: string) => {
        if(this.user && this.myPostVotes.indexOf(postId) >= 0) return true;
        else return false;
    }

    hasVotedOnComment = (commentId: string) => {
        if(this.user && this.myCommentVotes.indexOf(commentId) >= 0) return true;
        else return false;
    }

    setVoteOnPost = (postId: string, has: boolean) => {
        if(this.user) {
            if(has && this.myPostVotes.indexOf(postId) < 0) this.myPostVotes.push(postId);
            else if(this.myPostVotes.indexOf(postId) >= 0) this.myPostVotes = this.myPostVotes.filter(s => s != postId);
        }
    }

    setVoteOnComment = (commentId: string, has: boolean) => {
        if(this.user) {
            if(has && this.myCommentVotes.indexOf(commentId) < 0) this.myCommentVotes.push(commentId);
            else if(this.myCommentVotes.indexOf(commentId) >= 0) this.myCommentVotes = this.myCommentVotes.filter(s => s != commentId);
        }
    } 

    loadVotes = async () => {
        if(this.user) {
            try {
                const myProfile = await agent.Profiles.details(this.user?.username);
                runInAction(() => {
                    if(myProfile) {
                        this.myCommentVotes = [];
                        this.myPostVotes = [];
                        myProfile.votes.map((nextVote) => {
                            if(nextVote.postId) this.myPostVotes.push(nextVote.postId);
                            if(nextVote.commentId) this.myCommentVotes.push(nextVote.commentId);
                        })
                    }
                })
            } catch (error) {
                console.log(error);                
            }
        }
    }

    login = async(creds: UserFormValues) => {
        try{
            const user = await agent.Account.login(creds);
            store.commonStore.setToken(user.token);
            runInAction(() => {
                this.user = user;
            });
            history.push('/');
            store.modalStore.closeModal();
        } catch (error) {
            throw error;
        }
    }

    logout = () => {
        store.commonStore.setToken(null);
        window.localStorage.removeItem('jwt');
        this.user = null;
        history.push('/');
    }

    getUser = async () => {
        try {
            const user = await agent.Account.current();
            runInAction(() => this.user = user);
        } catch (error) {
            console.log(error);
        }
    }

    register = async (creds: UserFormValues) => {
        try{
            const user = await agent.Account.register(creds);
            store.commonStore.setToken(user.token);
            runInAction(() => this.user = user);
            history.push('/org');
            store.modalStore.closeModal();
        } catch (error) {
            throw error;
        }
    }
}