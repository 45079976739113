import { formatDistanceToNow } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { toast } from "react-toastify";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { Comment } from "../../app/models/comment";
import { Post } from "../../app/models/post";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";
import ForumLeftContent from "./LeftNavBarContent";
import ForumRightContent from "./ForumRightContent";
import PostCommentForm from "./PostCommentForm";

interface Props {
    comment: Comment;
}

export default observer(function CommentUpvoteButton({comment}: Props) {
    
    const {postStore, userStore} = useStore();
    const {user, hasVotedOnComment} = userStore;
    const {setCommentVote} = postStore;

    function handleClick() {
        if(!user) toast.error("You must be logged in to upvote");
        else setCommentVote(comment.id, !hasVotedOnComment(comment.id));
    }

    return (
        <div className="absolute top-0 left-0">
            <button className={`text-xs font-semibold text-left w-6 rounded-sm flex flex-col justify-center items-center ${hasVotedOnComment(comment.id) ? "text-lime-600" : "text-black"} hover:text-indigo-500`}
                onClick={() => handleClick()}>
                <svg className={`inline-flex ${hasVotedOnComment(comment.id) ? "fill-lime-600" : "fill-black"} mt-1.5 mb-1.5`} width="12" height="6" xmlns="http://www.w3.org/2000/svg">
                    <path d="m0 6 6-6 6 6z" />
                </svg>
                <div>{comment.points}</div>
            </button>
        </div>
    )
})