import React from "react";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import { Post } from "../../app/models/post";
import { useStore } from "../../app/stores/store";
import { formatDistanceToNow } from 'date-fns'
import PostUpvoteButton from "./PostUpvoteButton";
import extractDomain from "extract-domain";

interface Props {
  post: Post;
}

export default function PostAvatar ({post}: Props) {

  const avatarPath = "/images/avatars/";

  function urlToAvatar(url: string) {
    if(url.includes("youtube")) return avatarPath + "youtube.png";
    if(url.includes("bbc")) return avatarPath + "bbc.png";
    if(url.includes("road.cc")) return avatarPath + "roadcc.jpg";
    if(url.includes("reddit")) return avatarPath + "reddit.png";
    if(url.includes("redd.it")) return avatarPath + "reddit.png";
    if(url.includes("imgur")) return avatarPath + "imgur.png";
    if(url.includes("guardian")) return avatarPath + "guardian.png";
    if(url.includes("zwift")) return avatarPath + "zwift.jpg";
    if(url.includes("cyclingnews")) return avatarPath + "cyclingnews.png";
    if(url.includes("cyclist")) return avatarPath + "cyclist.png";
    else return "";
  } 

  if(!post.url || !urlToAvatar(post.url)) return null;

  return (
      <div className="shrink-0 mt-1.5">
        <img className="w-6 h-6 md:w-8 md:h-8 rounded-md" src={urlToAvatar(post.url)} width="32" height="32" alt="Post Logo" />
      </div>
  )
}