import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import DomainStore from "./domainStore";
import ModalStore from "./modalStore";
import PostStore from "./postStore";
import ProfileStore from "./profileStore";
import UserStore from "./userStore";

interface Store {
    commonStore: CommonStore;
    postStore: PostStore;
    userStore: UserStore;
    modalStore: ModalStore;
    profileStore: ProfileStore;
    domainStore: DomainStore;
}

export const store: Store = {
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    modalStore: new ModalStore(),
    postStore: new PostStore(),
    profileStore: new ProfileStore(),
    domainStore: new DomainStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}