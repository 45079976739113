import { isThisHour } from "date-fns";
import { th } from "date-fns/locale";
import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { CommentCreateDto, Comment } from "../models/comment";
import { Post, PostCreateDto } from "../models/post";
import { Profile } from "../models/profile";

export default class ProfileStore {

    profileRegistory = new Map<string, Profile>();
    selectedProfile: Profile | undefined = undefined;
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    public loadProfile = async (username: string) => {

        this.selectedProfile = undefined;
        let profile = this.profileRegistory.get(username);
        if(profile) {
            this.selectedProfile = profile;
            return profile;
        }
        else{
            this.setLoading(true);
            try {
                profile = await agent.Profiles.details(username);
                this.setProfile(profile);
                runInAction(() => {
                    if(profile) {
                        this.selectedProfile = profile;
                    }
                })
                this.setLoading(false);
                return profile;
            } catch (error) {
                console.log(error);
                this.setLoading(false);
            }
        }
    }
  
    private setProfile = (profile: Profile) => {
        this.profileRegistory.set(profile.username, profile);
    }

    private setLoading = (state: boolean) => {
        this.loading = state;
    }

}
