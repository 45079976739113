import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Comment } from "../../app/models/comment";
import { Post } from "../../app/models/post";
import { useStore } from "../../app/stores/store";
import * as Yup from 'yup';
import MyTextArea from "../../app/common/form/MyTextArea";
import ValidationErrors from "../errors/ValidationErrors";

interface Props {
    comment: Comment | null;
}

export default observer(function PostCommentForm({comment}: Props) {

    const {postStore, userStore} = useStore();
    const {user} = userStore;

    const {addComment, selectedPost} = postStore;
    
    if(!selectedPost) return null;

    const commentPlaceholder = user ? "Write a comment…" : "You must login first to leave a comment";

    return (
        <div>
            <div className="flex items-start space-x-3 mb-2">
                <div className="grow">
                    <Formik
                        initialValues={{body: '',
                            postId: selectedPost.id,
                            parentId: (comment ? comment.id : null),
                            error: null}}
                        onSubmit={(values, {setErrors}) => addComment(values).catch(error => 
                            setErrors({error}))}
                            validationSchema={Yup.object({
                                body: Yup.string().required().min(5),
                            })}
                    >
                        {({handleSubmit, isSubmitting, errors, isValid, dirty}) => (
                            <Form className='ui form error' onSubmit={handleSubmit} autoComplete='off'>
                                <div>
                                    <div className="space-y-2 pb-2">
                                        <MyTextArea name='body' placeholder={commentPlaceholder} disabled={!user} rows={2} />
                                        <ErrorMessage 
                                            name='error' render={() => 
                                                <ValidationErrors errors={errors.error} /> }
                                        />
                                    </div>
                                    <div className="text-right">
                                        <button
                                            disabled={!isValid || !dirty || isSubmitting} 
                                            className="btn rounded-none bg-black hover:bg-slate-700 cursor-pointer text-white"
                                            type='submit'
                                            >Reply ✒️</button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            
        </div>
    )
})