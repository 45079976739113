import axios, { AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Comment, CommentCreateDto } from "../models/comment";
import { Domain } from "../models/domain";
import { PaginatedResult } from "../models/pagination";
import { Post, PostCreateDto } from "../models/post";
import { Profile } from "../models/profile";
import { CreateRssFeedDTO, GeneratePostsDTO, PollFeedsDTO, RssFeedDTO } from "../models/rssFeed";
import { User, UserFormValues } from "../models/user";
import { store } from "../stores/store";

const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay)
    })
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if(token) config.headers!.Authorization = `Bearer ${token}`
    return config;
})

axios.interceptors.response.use(async response => {
    if(process.env.NODE_ENV === 'development') {
        await sleep(1000);
    }
    
    const pagination = response.headers['pagination'];
    if(pagination) {
        response.data = new PaginatedResult(response.data, JSON.parse(pagination));
        return response as AxiosResponse<PaginatedResult<any>>
    }

    return response;

}, (error: AxiosError) => {
    
    const{data, status, config} = error.response!;
 
    switch(status) {
        case 400:
            if(typeof data === 'string') {
                toast.error(data);
            }
            if(data.errors){
                const modalStateErrors = [];
                for (const key in data.errors){
                    if (data.errors[key]) {
                        modalStateErrors.push(data.errors[key]);
                    }
                }
                throw modalStateErrors.flat();
            }
            break;
        case 401:
            toast.error('Unauthorised');
            break;
        case 404:
            toast.error('Not Found');
            break;
        case 500:
            store.commonStore.setServerError(data);
            toast.error('Server Error');
            break;
    }
    return Promise.reject(error);
})

const responseBody = <T> (response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T> (url: string) => axios.get<T>(url).then(responseBody),
    post: <T> (url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T> (url: string) => axios.delete<T>(url).then(responseBody)
}

const Account = {
    current: () => requests.get<User>('/account'),
    login: (user: UserFormValues) => requests.post<User>('/account/login', user),
    register: (user: UserFormValues) => requests.post<User>('/account/register', user)
}

const Posts = {
    list: (params: URLSearchParams) => 
        axios.get<PaginatedResult<Post[]>>('/posts', {params}).then(responseBody),
    details: (id: string) => requests.get<Post>(`/posts/${id}`),
    detailsSlug: (slug: string) => requests.get<Post>(`/posts/slug/${slug}`),
    create: (post: PostCreateDto) => requests.post<Post>('/posts/create', post),
    delete: (id: string) => requests.del(`/posts/${id}`),
    upVote: (id: string) => requests.post(`/posts/${id}/upVote`, {}),
    downVote: (id: string) => requests.post(`/posts/${id}/downVote`, {}),
    addComment: (comment: CommentCreateDto) => requests.post<Comment>(`/posts/${comment.postId}/comment`, comment),
    deleteComment: (postId: string, commentId: string) => requests.del(`/posts/${postId}/comment/${commentId}`),
    upVoteComment: (postId: string, commentId: string) => requests.post(`/posts/${postId}/comment/${commentId}/upVote`, {}),
    downVoteComment: (postId: string, commentId: string) => requests.post(`/posts/${postId}/comment/${commentId}/downVote`, {}),
}

const Profiles = {
    details: (username: string) => requests.get<Profile>(`/profiles/${username}`),
}

const DomainNames = {
    details: (domainName: string) => requests.get<Domain>(`/domains/${domainName}`),
}

const Admin = {
    pollFeeds: () => requests.get<PollFeedsDTO>(`/robot/pollFeeds`),
    generateFeedPosts: () => requests.get<GeneratePostsDTO>(`/robot/generateFeedPosts`),
    reRank: () => requests.get(`/robot/reRank`),
    createRssFeed: (feed: CreateRssFeedDTO) => requests.post<RssFeedDTO>(`/robot/CreateRssFeed`, feed)
}

const agent = {
    Account,
    Posts,
    Profiles,
    Admin,
    DomainNames
}

export default agent;