import { isThisHour, isThisSecond } from "date-fns";
import { th } from "date-fns/locale";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { toast } from "react-toastify";
import { textChangeRangeIsUnchanged } from "typescript";
import agent from "../api/agent";
import { CommentCreateDto, Comment } from "../models/comment";
import { Post, PostCreateDto } from "../models/post";
import { Pagination, PagingParams } from "./pagination";
import { store } from "./store";

export default class PostStore {

    postRegistory = new Map<string, Post>();
    slugMap = new Map<string, string>();

    selectedPost: Post | undefined = undefined;
    loading: boolean = false;

    recentPosts: Post[] | undefined = undefined;

    pagination: Pagination | null = null;
    pagingParams = new PagingParams();
    predicate = new Map().set('all', true);

    commentMap = new Map<string | null, Comment[]>();
    
    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.predicate.keys(),
            () => {
                this.pagingParams = new PagingParams();
                this.postRegistory.clear();
            }
        )
    }

    get postsByCreatedAt() {
        const start = !this.pagination || this.pagination!.currentPage < 2 ? 0 : this.pagination!.itemsPerPage * (this.pagination!.currentPage - 1);
        const end = this.pagination ? start + (this.pagination!.itemsPerPage) : this.postRegistory.size;

        return Array.from(this.postRegistory.values())
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        .slice(start, end);
    }

    get postsByRank() {
        const start = !this.pagination || this.pagination!.currentPage < 2 ? 0 : this.pagination!.itemsPerPage * (this.pagination!.currentPage - 1);
        const end = this.pagination ? start + (this.pagination!.itemsPerPage) : this.postRegistory.size;

        return Array.from(this.postRegistory.values())
        .sort((a, b) => a.rank - b.rank)
        .slice(start, end);
    }

    get latestPosts() {
        return this.recentPosts;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append("pageNumber", this.pagingParams.pageNumber.toString());
        params.append("pageSize", this.pagingParams.pageSize.toString());
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, (value as Date).toISOString());
            } else {
                params.append(key, value);
            }
        })
        return params;
    }
    
    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPredicate = (predicate: string, value: string | Date) => {
        const resetPredicate = () => {
            this.predicate.forEach((value, key) => {
                this.predicate.delete(key);  
            })
        }
        switch (predicate) {
            case 'all':
            case 'popular':
                resetPredicate();
                break;
            case 'new':
                resetPredicate();
                this.predicate.set('onlyNew', true);
                break;
            case 'ask':
            case 'show':
                resetPredicate();
                this.predicate.set('onlyAsk', true);
                break;
            case 'videos':
                resetPredicate();
                this.predicate.set('onlyVideos', true);
                break;
        }
    }

    public silentLoadPosts = async () => {
        try {
            const result = await agent.Posts.list(this.axiosParams);
            result.data.forEach(post => {
                this.setPost(post);
            });
            this.setPagination(result.pagination);
        } catch (error) {
            console.log(error);
        }  
    }

    public loadPosts = async () => {
        this.setLoading(true);
        try {
            this.silentLoadPosts();
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
        }  
    }

    public loadRecentPosts = async () => {
        try {
            const params = new URLSearchParams();
            params.append("pageNumber", "1");
            params.append("pageSize", "6");
            params.append("onlyNew", "true");
            const recent = await agent.Posts.list(params)
            runInAction(() => {
                this.setRecentPosts(recent.data);
            });
        } catch (error) {
            console.log(error);
        }
    }

    setRecentPosts = (posts: Post[]) => {
        this.recentPosts = posts;
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    public create = async (post: PostCreateDto) => {
        this.setLoading(true);
        try {
            const newPost = await agent.Posts.create(post);
            runInAction(() => {
                if(newPost) {
                    this.setPost(newPost);
                    this.loadPost(newPost.id);
                }
                this.setLoading(false);
            })
        } catch (error) {
            console.log(error);
            this.setLoading(false);
        }
    }

    public addComment = async (comment: CommentCreateDto) => {
        try {
            const newComment = await agent.Posts.addComment(comment);
            runInAction(() => {
                const post = this.postRegistory.get(comment.postId);
                if(post && newComment) post.comments.push(newComment);

                const commentGroup = this.commentMap.get(comment.parentId);
                if(commentGroup) commentGroup.push(newComment);
                else this.commentMap.set(newComment.parentId, [newComment]);
            })
        } catch (error) {
            console.log(error);
        }
    }

    public deletePost = async (id: string) => {
        this.setLoading(true);
        try {
            await agent.Posts.delete(id);
            runInAction(() => {
                this.postRegistory.delete(id);
                if(this.selectedPost?.id === id) this.selectedPost = undefined;
            })
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
        }
    }

    public deleteComment = async (commentId: string) => {
        
        if(!this.selectedPost) return null;

        try {
            await agent.Posts.deleteComment(this.selectedPost.id, commentId);
            runInAction(() => {
                if(this.selectedPost) {
                    const comment = this.selectedPost.comments.find(s => s.id === commentId);
                    if(comment) {
                        comment.deletedAt = new Date();
                        const map = this.commentMap.get(comment.parentId);
                        if(map) {
                            this.commentMap.set(comment.parentId, map.filter(s => s.id !== comment.id));
                        }
                    }
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    public loadPostBySlug = async (slug: string) => {
        const postId = this.slugMap.get(slug);
        if(postId) {
            await this.loadPost(postId);
        }
        else {
            this.setLoading(true);
            try {
                const post = await agent.Posts.detailsSlug(slug);
                this.setPost(post);
                runInAction(() => {
                    if(post) {
                        this.selectedPost = post;
                        this.loadComments();
                    }
                })
                this.setLoading(false);
                return post;
            } catch (error) {
                console.log(error);
                this.setLoading(false);
            }
        }
    }

    public loadPost = async (id: string) => {

        this.selectedPost = undefined;
        let post = this.postRegistory.get(id);
        if(post) {
            this.selectedPost = post;
            this.loadComments();
            return post;
        }
        else{
            this.setLoading(true);
            try {
                post = await agent.Posts.details(id);
                this.setPost(post);
                runInAction(() => {
                    if(post) {
                        this.selectedPost = post;
                        this.loadComments();
                    }
                })
                this.setLoading(false);
                return post;
            } catch (error) {
                console.log(error);
                this.setLoading(false);
            }
        }
    }

    private loadComments = () => {
        // Clear the old map
        this.commentMap.clear();
        this.commentMap.set(null, []);

        // Stop if no post or comments
        if(!this.selectedPost || this.selectedPost.comments.length < 1)  return;

        // Find all the unqiue levels
        const uniqueParents = this.selectedPost.comments.map(item => item.parentId)
            .filter((value, index, self) => self.indexOf(value) === index);

        // Filter for each one, and populate the map
        uniqueParents.map((parent) => {
            if(this.selectedPost) {
                this.commentMap.set(parent, 
                    this.selectedPost.comments.filter(s => s.parentId === parent));
            }
        });
    }

    public setPostVote = async (postId: string, setTo: boolean) => {
        try {
            const post = this.postRegistory.get(postId);
            if(post) {
                if(setTo){
                    await agent.Posts.upVote(postId);
                    runInAction(() =>  post.points++);
                }  else {
                    await agent.Posts.downVote(postId);
                    runInAction(() =>  post.points--);
                } 
                store.userStore.setVoteOnPost(postId, setTo);
            }
        } catch (error) {
            console.log(error);
        }
    }

    public setCommentVote = async (commendId: string, setTo: boolean) => {
        if(!this.selectedPost) return null;
        try {
            const comment = this.selectedPost.comments.find(s => s.id === commendId);
            if(comment) {
                if(setTo) {
                    await agent.Posts.upVoteComment(this.selectedPost.id, commendId);
                    runInAction(() =>  comment.points++);
                } else {
                    await agent.Posts.downVoteComment(this.selectedPost.id, commendId);
                    runInAction(() =>  comment.points--);
                }
                store.userStore.setVoteOnComment(commendId, setTo);
            }
        } catch (error) {
            console.log(error);
        }
    }

    public registerPost = (post: Post) => {
        this.setPost(post);
    }  

    private setPost = (post: Post) => {
        this.postRegistory.set(post.id, post);
        this.slugMap.set(post.slug, post.id);
    }

    private setLoading = (state: boolean) => {
        this.loading = state;
    }

}
