import React from "react";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import { Post } from "../../app/models/post";

interface Props {
  post: Post | null;
}

export default observer (function AdvertRow ({post}: Props) {

    const UserAvatar = 'https://preview.cruip.com/mosaic/images/user-32-02.jpg';
    const navigate = useNavigate();

    return (
      <article className="bg-amber-50 shadow-md rounded border border-amber-300 px-5 py-2">
        <div className="flex flex-start space-x-4">
          {/* Avatar */}
          <div className="shrink-0 mt-1.5">
            <svg className="w-8 h-8 rounded-full" width="32" height="32" xmlns="http://www.w3.org/2000/svg">
              <path fill="#3B82F6" d="M0 0h32v32H0z" />
              <path
                d="M16.561 22c3.061 0 5.327-2.088 5.939-4.642h-3.449c-.367.69-1.04 1.5-2.449 1.5-1.612 0-2.694-1.216-2.694-2.858 0-1.622 1.082-2.94 2.694-2.858 1.408 0 2.082.81 2.449 1.581h3.429c-.572-2.757-3-4.723-6-4.723-3.388 0-5.98 2.676-5.98 5.98 0 3.344 2.633 6.02 6.061 6.02Z"
                fill="#EFF6FF"
                fillRule="nonzero"
              />
            </svg>
          </div>
          {/* Content */}
          <div className="grow">
            {/* Title */}
            <h2 className="font-semibold text-slate-800 mb-2">
              <div>
              ✨ The new Cube Reaction Hybird Pro is here, step up your eBike game!!
              </div>
            </h2>
            {/* Footer */}
            <footer className="flex flex-wrap text-sm">
              <div className="flex items-center after:block after:content-['·'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-2">
                <span className="font-medium text-indigo-500 hover:text-indigo-600">
                  Cube.EU
                </span>
              </div>
              <div className="flex items-center after:block after:content-['·'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-2">
                <span className="text-slate-500 italic">Promoted</span>
              </div>
            </footer>
          </div>
        </div>
      </article>
    )
})