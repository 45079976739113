import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStore } from '../../app/stores/store';

interface Props {
  name: string;
  url: string;
  filter: string;
  icon: string;
}

export default observer(function LeftNavBarButton({name, url, filter, icon}: Props) {

  const navigate = useNavigate();

  const {postStore} = useStore();
  const {setPredicate} = postStore;

  function handleClick(destinationUrl: string, predicateFilter: string) {
    setPredicate(predicateFilter, "true");
    navigate(destinationUrl);
  }

  let active: boolean = window.location.pathname.startsWith(url);
  if(url === '/' && window.location.pathname.length !== 1) active = false;

  return (
    <li className="mr-0.5 md:mr-0 md:mb-0.5">
      <a className="flex items-center cursor-pointer whitespace-nowrap bg-black hover:bg-slate-800 hover:text-lime-300 text-white"
        onClick={() => handleClick(url, filter)}>
        <span className={`${active ? 'bg-lime-500' : 'bg-yellow-300'} px-1 md:px-2 py-2 w-10 text-center`}>{icon}</span>
        <div className='hidden md:block'>
          <span className="text-sm md:text-md font-medium pl-3">{name}</span>
        </div>
      </a>
    </li>
  );
})