import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { toast } from "react-toastify";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";
import ForumLeftContent from "./LeftNavBarContent";
import ForumRightContent from "./ForumRightContent";
import PostCommentForm from "./PostCommentForm";
import PostComments from "./PostComments";
import { formatDistanceToNow } from 'date-fns'
import PostUpvoteButton from "./PostUpvoteButton";
import YoutubeEmbed from "./YoutubeEmbed";
import Spinner from "../Spinner";

export default observer(function PostDetails() {

    const navigate = useNavigate();

    const {postStore, userStore} = useStore();
    const {selectedPost, deletePost} = postStore;
    const {user, isAdmin} = userStore;

    const userProfile = `/users/${selectedPost?.username}`

    const [deleteConfirm, setDeleteConfirm] = useState(false);

    const internalLink =  selectedPost && (selectedPost.body || selectedPost.url.toLowerCase().includes("youtube") || !selectedPost.url);
    function openExternalLink(link: string) {
        window.open(link);
    }

    const deleteConfirmed = async () => {
        if(selectedPost) {
            await deletePost(selectedPost.id);
            toast.info("Post deleted!");
            setDeleteConfirm(false);
            navigate('/');
        }
    }

    return (
        <>
            {selectedPost ? (
                <article className="bg-white border-2 border-slate-200 p-5">
                    {/* Breadcrumbs */}
                    <div className="mb-2">
                        <ul className="inline-flex flex-wrap text-sm font-medium">
                        <li className="flex items-center">
                            <Link className="text-slate-500 hover:text-yellow-500" to="/">
                                Home
                            </Link>
                            <svg className="h-4 w-4 fill-current text-slate-400 mx-2" viewBox="0 0 16 16">
                            <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
                            </svg>
                        </li>
                        <li className="flex items-center">
                            <Link className="text-slate-500 hover:text-yellow-500" to="/">
                                Posts
                            </Link>
                        </li>
                        </ul>
                    </div>
                    {/* Header */}
                    <header className="pb-4">
                        {/* Title */}
                        <div className="flex flex-start space-x-3">
                            <div className="grow">
                                {!internalLink ? (
                                    <a onClick={() => openExternalLink(selectedPost.url)}>
                                        <h2 className="text-2xl text-slate-800 hover:text-rose-800 cursor-pointer font-bold pb-3">{selectedPost.title}</h2>
                                    </a>
                                ) : (
                                    <h2 className="text-2xl text-slate-800 font-bold pb-3">{selectedPost.title}</h2>
                                )}
                            </div>
                            <div className="float-right">
                                <PostUpvoteButton post={selectedPost} />
                            </div>
                        </div>

                        {/* Meta */}
                        <div className="flex flex-wrap text-sm">
                            <div className="flex items-center after:block after:content-['·'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-2">
                                <Link className="font-medium text-yellow-500 hover:text-yellow-600" to={userProfile}>
                                    {selectedPost.username}
                                </Link>
                            </div>
                            <div className="flex items-center after:block after:content-['·'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-2">
                                <span className="text-slate-500">{formatDistanceToNow(new Date(selectedPost.createdAt + 'Z'))} ago</span>
                            </div>
                            <div className="flex items-center after:block after:content-['·'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-2">
                                <span className="text-slate-500">{selectedPost.comments.length} Comments</span>
                            </div>
                            {selectedPost.domainName && (
                                <div className="flex items-center after:block after:content-['·'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-2">
                                    <Link to={`/domains/${selectedPost.domainName}`}>
                                        <span className="text-yellow-500 font-bolder">{selectedPost.domainName}</span>
                                    </Link>
                                </div>
                            )}
                            {(isAdmin || selectedPost.username == user?.username) && (
                                <>
                                    <div className="flex items-center after:block after:content-['·'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-2">
                                        <a className="text-rose-600 hover:bg-rose-700 hover:text-white cursor-pointer px-1"
                                        onClick={() => setDeleteConfirm(!deleteConfirm)}  >{deleteConfirm ? "Cancel" : "Delete"}</a>
                                    </div>
                                    {deleteConfirm && (
                                        <>
                                            <div className="border rounded-md w-full bg-rose-200 px-5 py-2 my-2 text-center">
                                                Warning - You are about to delete a post and all it's comments?! <br />
                                                <button
                                                    className="border bg-rose-800 text-white rounded-md px-2 py-1 my-3"
                                                    onClick={() => deleteConfirmed()}>CONFIRM DELETE</button>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>

                    </header>

                    {/* Content */}
                    <div className="space-y-4 mb-6 text-md text-slate-800">
                        {selectedPost.body}
                    </div>

                    <YoutubeEmbed postUrl={selectedPost.url} />

                    {/* Comment form */}
                    <PostCommentForm comment={null} />

                    {/* Comments */}
                    <PostComments />
                </article>
            ) : (
                <div className="bg-white shadow-md rounded border border-slate-200 p-5">
                    <Spinner />
                </div>
            )}
        </>
    )
})