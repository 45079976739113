import { RocketIcon } from '@primer/octicons-react';
import '../layout/styles.scss'
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import SimDetails from '../../features/posts/PostDetailsPage';
import SimList from '../../features/posts/PostHome';
import ModalContainer from '../common/modals/ModalContainer';
import { useStore } from '../stores/store';
import LoginPage from '../../features/auth/LoginPage';
import RegisterPage from '../../features/auth/RegisterPage';
import PostList from '../../features/posts/PostHome';
import PostDetails from '../../features/posts/PostDetailsPage';
import PostHome from '../../features/posts/PostHome';
import PostCreate from '../../features/posts/PostCreate';
import UserProfilePage from '../../features/profiles/UserProfilePage';
import AdminHome from '../../features/admin/AdminHome';
import DomainPage from '../../features/domains/DomainPage';

export default observer(function App() {

    const {commonStore, userStore} = useStore();

    // Check for a token, and if we have one get the user from the API
    useEffect(() => {
      if(commonStore.token) {
            userStore.getUser().finally(() => commonStore.setAppLoaded());
    } else {
        commonStore.setAppLoaded();
      }
    }, [commonStore, userStore]);

    return (
        <>
            <ToastContainer position='bottom-right' hideProgressBar />
            <ModalContainer />
            <Routes>

                <Route path="/" element={<PostHome />} />
                
                <Route path="/popular" element={<PostHome />} />
                <Route path="/new" element={<PostHome />} />
                <Route path="/ask" element={<PostHome />} />
                <Route path="/videos" element={<PostHome />} />
                <Route path="/show" element={<PostHome />} />

                <Route path="/login" element={<LoginPage />} />
                <Route path="/admin" element={<AdminHome />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/posts/create" element={<PostCreate />} />
                <Route path="/posts/:slug" element={<PostDetails />} />
                <Route path="/users/:username" element={<UserProfilePage />} />
                <Route path="/domains/:domainName" element={<DomainPage />} />

            </Routes>
        </>
    );
})