import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { toast } from "react-toastify";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";
import ForumLeftContent from "../posts/LeftNavBarContent";
import ForumRightContent from "../posts/ForumRightContent";
import PostCommentForm from "../posts/PostCommentForm";
import PostComments from "../posts/PostComments";
import { formatDistanceToNow } from 'date-fns'
import PostUpvoteButton from "../posts/PostUpvoteButton";
import PostListRow from "../posts/PostListRow";
import CommentRow from "../posts/CommentRow";
import Spinner from "../Spinner";

export default observer(function ProfileDetails() {

    const {profileStore} = useStore();
    const {loading, selectedProfile} = profileStore;

    return (
        <article className="border-2 border-black p-2">

            {!selectedProfile ? (
               <header className="pb-4">
                    {/* Title */}
                    <div className="flex flex-start p-5">
                        <Spinner />
                    </div>
                </header>
            ) : (
                <>
                    {/* Header */}
                    <header className="py-1 px-5 bg-black">
                        {/* Title */}
                        <div className="flex flex-start space-x-3">
                            <div className="grow">
                                <h2 className="text-2xl text-white font-bold"><span className="text-slate-500">User:</span> {selectedProfile.username}</h2>
                            </div>
                        </div>
                    </header>

                    {selectedProfile.posts.length > 0 && (
                        <div className="p-1">
                            <h2 className="text-black text-md md:text-lg pb-1 font-bold">Recent Posts</h2>
                            <div className="space-y-2">
                                {selectedProfile.posts.map((post) => (
                                    <PostListRow post={post} key={post.id} />
                                ))}
                            </div>
                        </div>
                    )}

                    {selectedProfile.comments.length > 0 && (
                        <div className="p-1">
                            <h2 className="text-black text-md md:text-lg pb-1 font-bold">Recent Comments</h2>
                            <div className="space-y-2">
                                {selectedProfile.comments.map((comment) => (
                                    <CommentRow comment={comment} displayChildren={false} key={comment.id} />
                                ))}
                            </div>
                        </div>
                    )}
                </>
            )}
           

        </article>
    )
})