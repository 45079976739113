import { Formik, Form, ErrorMessage } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';
import MyTextInput from '../../app/common/form/MyTextInput';
import { useStore } from '../../app/stores/store';
import * as Yup from 'yup';
import ValidationErrors from '../errors/ValidationErrors';
import { Link } from 'react-router-dom';

export default observer( function RegisterForm(){

    const {userStore} = useStore();

    return (
        <div className="max-w-sm mx-auto px-4 py-8">
            <h1 className="text-3xl text-slate-800 font-bold mb-6">✨ Create Your Account!</h1>
            <Formik
                initialValues={{displayName: '', username: '', email: '', password: '', error: null}}
                onSubmit={(values, {setErrors}) => userStore.register(values).catch(error => 
                    setErrors({error}))}
                    validationSchema={Yup.object({
                        username: Yup.string().required(),
                        email: Yup.string().required().email(),
                        password: Yup.string().required(),
                    })}
            >
                {({handleSubmit, isSubmitting, errors, isValid, dirty}) => (
                    <Form className='ui form error' onSubmit={handleSubmit} autoComplete='off'>
                        <div>
                            <div className="space-y-4">
                                <MyTextInput name='email' label='eMail Address' placeholder='Email' />
                                <MyTextInput name='username' label='Username' placeholder='Username' />
                                <MyTextInput name='password' label='Password' placeholder='Password' type='password' />
                                <ErrorMessage 
                                    name='error' render={() => 
                                        <ValidationErrors errors={errors.error} /> }
                                />
                            </div>
                            <div className="flex items-center justify-between mt-6">
                                <div className="mr-1">
                                    <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox" />
                                    <span className="text-sm ml-2">I agree to the Terms.</span>
                                    </label>
                                </div>
                                <button
                                    disabled={!isValid || !dirty || isSubmitting} 
                                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3"
                                    type='submit'
                                    >Register</button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
             {/* Footer */}
             <div className="pt-5 mt-6 border-t border-slate-200">
                <div className="text-sm">
                    Already have an account? <Link className="font-medium text-indigo-500 hover:text-indigo-600" to="/login">Sign In</Link>
                </div>
            </div>
        </div>
    )
})