import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Post } from '../../app/models/post';
import Spinner from '../Spinner';
import LatestPostRow from './LatestPostRow';

interface Props {
  posts: Post[] | undefined;
}

export default observer (function LatestPosts({posts}: Props) {
  
  return (
    <div className="border-black border-2">
      <div className="text-md font-medium text-center text-yellow-200 bg-black uppercase mb-4 py-2">Latest Posts</div>
      <div className='px-2'>
        {posts ? (
          <ul className="space-y-2 md:space-y-3">
            {posts.map((post) => (
              <LatestPostRow post={post} key={post.id} />
            ))}
          </ul>
        ) : (
          <Spinner />
        )}

        <div className='text-yellow-600 hover:text-lime-700 text-center py-3 text-sm'>
          <Link  to="/new">
            See More...
          </Link>
        </div>
      </div>
    </div>
  );
})
