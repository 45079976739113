import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { User, UserFormValues } from "../models/user";
import { store } from "./store";
import { history } from "../..";
import { useNavigate } from "react-router-dom";
import { isGenerator } from "mobx/dist/internal";
import { bool } from "yup";
import { Domain } from "../models/domain";
import { ADDRGETNETWORKPARAMS } from "dns";

export default class DomainStore{

    domain: Domain | undefined = undefined;
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    domainPosts = () => {
        if(this.domain) return this.domain.posts;
    }

    public loadDomain = async (domainName: string) => {

        domainName = domainName.trim().toLowerCase();

        if(this.domain && this.domain.name === domainName) return;

        this.domain = undefined;
        try {
            const domain = await agent.DomainNames.details(domainName);
            runInAction(() => {
                if(domain) {
                    this.setDomain(domain);
                }
                this.setLoading(false);
                return domain
            });
        } catch (error) {
            this.setLoading(false);
            console.log(error);
        }
    }

    setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    setDomain = (domain: Domain) => {
        // Make sure any posts for this domain are registered with the postStore
        domain.posts.map((nextPost) => store.postStore.registerPost(nextPost));

        this.domain = domain;
    }
}