import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Post } from '../../app/models/post';
import { formatDistanceToNow } from 'date-fns'

interface Props {
  post: Post;
}

export default observer (function LatestPostRow({post}: Props) {

  const navigate = useNavigate();

  if(!post) return null;

  const internalLink = post.body || post.url.toLowerCase().includes("youtube") || !post.url;

  function openExternalLink(link: string) {
    window.open(link);
  }

  return (
    <li className='pb-1 md:pb-2'>
      <div className="text-sm">
        {internalLink ? (
          <Link className="font-medium text-slate-800 hover:text-lime-500 cursor-pointer" to={`/posts/${post.slug}`}>
            {post.title}
          </Link>
        ) : (
          <a className="font-medium text-slate-800 hover:text-rose-700 cursor-pointer" onClick={() => openExternalLink(post.url)}>
            {post.title}
          </a>
        )}
      </div>
      <div className="text-xs text-slate-500">
        <Link className="font-medium text-yellow-500 hover:text-lime-500" to={`/users/${post.username}`}>
          {post.username}
        </Link>{' '}
        · {formatDistanceToNow(new Date(post.createdAt + 'Z'))} ago · 
        <Link to={`/posts/${post.slug}`} className="pl-1">
          {post.comments.length} comments
        </Link>
      </div>
    </li>
  );
})
