import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../app/stores/store';
import DropdownProfile from './DropdownProfile';

interface Props {
    sidebarOpen: boolean;
    setSidebarOpen: any;
}

export default observer (function Header({
  sidebarOpen,
  setSidebarOpen
}: Props) {

  const {userStore} = useStore();
  const {isAdmin} = userStore;

  const navigate = useNavigate();

  return (
    <header className="sticky top-0 z-30 ">
      <div className="px-4 sm:px-6 lg:px-8 bg-black py-1">
        <div className="flex items-center justify-between h-10 md:h-12 -mb-px max-w-7xl mx-auto">

          {/* Header: Left side */}
          <div className="flex">

            {/* Title */}
            <header className="mb-6 logoFont">
              <h1 className="text-2xl md:text-3xl pt-7 text-white text-center cursor-pointer hover:text-lime-300"
                onClick={() => navigate('/')}
                >BikeNatter<span className="text-md md:text-lg text-yellow-200">.com</span></h1>
            </header>

          </div>
          {/* Header: Right side */}
          <div className="flex items-center space-x-1 md:space-x-3">
            {isAdmin && (
              <button className='btn rounded-none bg-yellow-400 text-rose-700 hover:bg-yellow-200'
                onClick={() => navigate('/admin')}>🛠️</button>
            )}
            <DropdownProfile align="right" />
          </div>
        </div>
      </div>
    </header>
  );
})