import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useStore } from '../../app/stores/store';


export default observer (function CreatePostButton() {

  const {userStore} = useStore();
  const {user} = userStore;

  const navigate = useNavigate();

  function handleClick() {
    if(!user) toast.error("You must login to create a post");
    else navigate('/posts/create');
  }

  return (
      <div className="mb-1 md:mb-2">
        <button className="btn rounded-none w-full bg-black hover:text-lime-300 text-yellow-200 text-md md:text-lg h-10"
          onClick={() => handleClick()}
          >🚴 Submit <span className='hidden lg:block pl-1'> Post</span></button>
      </div>
  );
})
