import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { toast } from "react-toastify";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";
import ForumLeftContent from "./LeftNavBarContent";
import ForumRightContent from "./ForumRightContent";
import PostCommentForm from "./PostCommentForm";
import PostComments from "./PostComments";
import { formatDistanceToNow } from 'date-fns'
import { Post } from "../../app/models/post";

interface Props {
    post: Post;
}

export default observer(function PostUpvoteButton({post}: Props) {

    const {postStore, userStore} = useStore();
    const {user, hasVotedOnPost} = userStore;
    const {setPostVote} = postStore;

    function handleClick() {
        if(!user) toast.error("You must be logged in to upvote");
        else setPostVote(post.id, !hasVotedOnPost(post.id));
    }

    return (
        <div className="my-auto">
            <button className={`text-md md:text-lg font-bold text-center px-4 py-1 md:px-4 md:py-2 border-2  ${hasVotedOnPost(post.id) ? "bg-lime-200 border-lime-500" : "bg-yellow-100 border-black"} hover:bg-lime-300`}
            onClick={() => handleClick()}
            >
            <span className="text-sm md:text-md">{post.points}</span>
            </button>
        </div>
    )
})