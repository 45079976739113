import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Post } from "../../app/models/post";
import { useStore } from "../../app/stores/store";
import { formatDistanceToNow } from 'date-fns'
import PostUpvoteButton from "./PostUpvoteButton";
import PostAvatar from "./PostAvatar";

interface Props {
  post: Post;
}

export default observer (function PostListRow ({post}: Props) {

    const {userStore} = useStore();
    const {user} = userStore;


    const internalLink = post.body || post.url.includes("youtube") || !post.url;
    const postUrl = `/posts/${post.slug}`;
    const domainUrl = `/domains/${post.domainName}`;
    const authorUrl = `/users/${post.username}`;

    const removeFromStartOfDomain: string[] = ["https://www.", "https://", "http://www.", "http://"];

    function openExternalLink(link: string) {
      window.open(link);
    }

    return (
        <article className="shadow-md border-2 border-black px-1.5 md:px-4 py-0 md:py-1 hover:bg-white">
          <div className="flex flex-start space-x-2 md:space-x-4">
            
            {/* Avatar */}
            <div className="w-9 flex">
              <PostAvatar post={post} />
            </div>

            {/* Content */}
            <div className="grow">
              {/* Title */}
              <h2 className="font-semibold text-sm md:text-md text-slate-800 md:mb-1 hover:text-yellow-600">
                {internalLink ? (
                  <Link to={postUrl}>{post.title}</Link>
                ) : (
                  <a onClick={() => openExternalLink(post.url)} className="hover:text-rose-700 cursor-pointer">{post.title}</a>
                )}
              </h2>
              {/* Footer */}
              <footer className="flex flex-wrap text-sm">
                <div className="flex after:block after:content-['·'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-1 md:after:px-2">
                  <Link className={`font-medium ${post.username == user?.username ? "text-yellow-500 hover:text-lime-600" : "text-yellow-500 hover:text-lime-600"}`}
                  to={authorUrl}>
                    <div className="flex"> {post.username}</div>
                  </Link>
                </div>
                <div className="flex after:block after:content-['·'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-1 md:after:px-2">
                  <span className="text-slate-500">{formatDistanceToNow(new Date(post.createdAt + 'Z'))} ago</span>
                </div>
                <div className="flex after:block after:content-['·'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-1 md:after:px-2">
                  <Link to={postUrl}>
                    <span className="text-yellow-500 hover:text-lime-600">{post.comments.length} Comments</span>
                  </Link>
                </div>
                {post.domainName && (
                  <div className="flex after:block after:content-['·'] last:after:content-[''] after:text-sm after:text-slate-400 after:px-1 md:after:px-2">
                    <Link to={domainUrl}>
                      <span className="text-yellow-500 hover:text-lime-600">{post.domainName}</span>
                    </Link>
                  </div>
                )}
              </footer>
            </div>

            {/* UpVote */}
            <div className="w-10 flex">
              <PostUpvoteButton post={post} />
            </div>
          </div>
        </article>
    )
})