import { observer } from 'mobx-react-lite';
import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStore } from '../../app/stores/store';

import UserAvatar from '../images/user-avatar-32.png';

interface Props {
    align: string;
}

export default observer(function DropdownProfile({align}: Props) {

  const {userStore} = useStore();
  const {isLoggedIn, logout} = userStore;

  const navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ( target: any ) => {
      if (!dropdown.current) return;
      // if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      if (!dropdownOpen) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ( keyCode: any ) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <>
      {isLoggedIn ? (
        <div className="relative inline-flex bg-white px-1 md:px-3 py-1 m-1">
          <button
            ref={trigger}
            className="inline-flex justify-center items-center group"
            aria-haspopup="true"
            onClick={() => setDropdownOpen(!dropdownOpen)}
            aria-expanded={dropdownOpen}
          >
            <div className="flex items-center py-1">
              <span className="ml-2 text-sm md:text-md font-medium group-hover:text-yellow-500 pr-2">🚴 {userStore.user?.username}</span>
              <svg className="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400" viewBox="0 0 12 12">
                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
              </svg>
            </div>
          </button>

            {dropdownOpen && (
                <div className={`origin-top-right z-10 absolute top-full min-w-44 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${align === 'right' ? 'right-0' : 'left-0'}`}>
                    <div
                        ref={dropdown}
                        onFocus={() => setDropdownOpen(true)}
                        onBlur={() => setDropdownOpen(false)}
                    >
                        <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200">
                        <div className="font-medium text-slate-800">{userStore.user?.username}.</div>
                        </div>
                        <ul>
                          <li>
                              <button
                                className="font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3"
                                onClick={() => logout()}
                                >Sign Out</button>
                          </li>
                        </ul>
                    </div>
                </div>
            )}
        </div>
      ) : 
      (
        <div className="relative inline-flex">
          <button
            ref={trigger}
            className="inline-flex justify-center items-center group"
            aria-haspopup="true"
            onClick={() => setDropdownOpen(!dropdownOpen)}
            aria-expanded={dropdownOpen}
          >
            <div className="flex items-center truncate space-x-2">
            <span className="text-sm font-medium btn w-full bg-lime-400 hover:bg-lime-600 text-black rounded-none"
                onClick={() => navigate('/register')} >Register</span>
              <span className="text-sm font-medium btn w-full bg-yellow-400 hover:bg-yellow-600 text-black rounded-none"
                onClick={() => navigate('/login')} >Login</span>
            </div>
          </button>
        </div>
      )}
    </>
  )
})