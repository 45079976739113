import React from 'react';
import { Link } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import PostListRow from './PostListRow';
import { useStore } from '../../app/stores/store';
import AdvertRow from './AdvertRow';
import Spinner from '../Spinner';

export default observer (function PostList() {
    
  const {postStore} = useStore();
  const {postsByRank, loading} = postStore;

  const showAdvert = false;

  return (
    <>
      {loading ? (
          <Spinner />
      ) : (
        <>
          {postsByRank.map((post) => (
            <PostListRow post={post} key={post.id} />
          ))}

          {showAdvert && (
            <AdvertRow post={null} />
          )}
        </>
      )}
    </>
  );
})
