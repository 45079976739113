import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";
import ForumEntries from "./PostList";
import ForumLeftContent from "./LeftNavBarContent";
import ForumRightContent from "./ForumRightContent";
import PostList from "./PostList";
import PostPagination from "./PostPagination";
import LeftNavBarContent from "./LeftNavBarContent";

export default observer(function PostHome() {

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const {postStore} = useStore();
    const {postRegistory, loadPosts, loading} = postStore;

    useEffect(() => {
        if(postRegistory.size <= 1) {
            loadPosts();
        }
    }, [postRegistory.size, postStore, loadPosts, loading]);

    return (

        <div className="flex baseColor h-full">

            {/* Content area */}
            <div className="relative flex flex-col flex-1">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>

                    <div className="px-4 sm:px-6 lg:px-8 py-2 md:py-0 w-full max-w-9xl mx-auto">

                        <div className="xl:flex">

                            {/* Left + Middle content */}
                            <div className="md:flex flex-1">

                                {/* Left content */}
                                <LeftNavBarContent />

                                {/* Middle content */}
                                <div className="flex-1 md:ml-8 xl:mx-4 2xl:mx-8">
                                    <div className="md:py-8">

                                        <div className="space-y-1 md:space-y-2">
                                            <PostList />
                                        </div>

                                        {/* Pagination */}
                                        <PostPagination />

                                    </div>
                                </div>                

                            </div>

                            {/* Right content */}
                            <ForumRightContent />              

                        </div>


                    </div>
                    
                </main>

            </div>

        </div>
    )
})