import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStore } from '../../app/stores/store';
import CreatePostButton from './CreatePostButton';
import LatestPosts from './LatestPosts';
import LeftNavBarButton from './LeftNavBarButton';

export default observer (function LeftNavBarContent() {

  const navigate = useNavigate();

  const {postStore} = useStore();
  const {setPredicate, recentPosts, loadRecentPosts} = postStore;

  useEffect(() => {
    if(recentPosts === undefined || recentPosts.length <= 1) loadRecentPosts();
  }, [recentPosts, recentPosts?.length, postStore, loadRecentPosts]);

  function handleClick(to: string, filter: string) {
    setPredicate(filter, "true");
    navigate(to);
  }

  return (
    <div className="w-full md:w-60 mb-2 md:mb-0">
      <div className="md:top-16 md:h-[calc(100vh-64px)]">
        <div className="md:py-8">
          <div className="flex justify-between items-center md:block">

            {/* Button */}
            <div className="mb-0 hidden md:block xl:hidden">
              <CreatePostButton />
            </div>

          </div>

          {/* Links */}
          <div className="flex flex-nowrap md:block px-4 md:space-y-3 -mx-4 md:pb-2">
            {/* Group 1 */}
            <div className='w-full'>
              <ul className="flex md:flex-nowrap md:block mr-3 md:mr-0 md:space-y-2 justify-center">
                <LeftNavBarButton name='Home' url='/' filter='all' icon='🏠' />
                <LeftNavBarButton name='Popular' url='/popular' filter='all' icon='🔥' />
                <LeftNavBarButton name='Ask/Show' url='/ask' filter='ask' icon='🚴' />
                <LeftNavBarButton name='Videos' url='/videos' filter='videos' icon='📺' />
                <LeftNavBarButton name='Latest' url='/new' filter='new' icon='✨' />
                <div className="block md:hidden">
                  <CreatePostButton />
                </div>
              </ul>
            </div>
          </div>

          <div className="hidden md:block xl:hidden mb-0 md:mb-6">
            <LatestPosts posts={recentPosts} />
          </div>

        </div>
      </div>
    </div>
  );
})
