import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../app/stores/store';
import CreatePostButton from './CreatePostButton';
import LatestPostRow from './LatestPostRow';
import LatestPosts from './LatestPosts';


export default observer (function ForumRightContent() {

  const navigate = useNavigate();

  const {postStore} = useStore();
  const {recentPosts, loadRecentPosts} = postStore;

  useEffect(() => {
    if(recentPosts === undefined || recentPosts.length <= 1) loadRecentPosts();
  }, [recentPosts, recentPosts?.length, postStore, loadRecentPosts]);

  return (
    <div className="w-full hidden xl:block xl:w-72">
      <div className="lg:h-[calc(100vh-64px)]">
        <div className="md:py-8">
          
          {/* Button */}
          <CreatePostButton />

          {/* Blocks */}
          <div className="space-y-4">
            
            {/* Recent Posts */}
            <LatestPosts posts={recentPosts} />
            
          </div>
        </div>
      </div>
    </div>
  );
})
