import React from "react";

interface Props {
    postUrl: string;
}

export default function YoutubeEmbed ({ postUrl }: Props) {

    if(!postUrl || !postUrl.toLowerCase().includes("youtube")) return null;

    const embedId = postUrl.split("=")[1];

    return (
        <div className="video-responsive mb-8 shadow-lg">
            <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            />
        </div>
    )
}